import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import ArticleCard from '../components/articleCard';
import Pagination from '../components/pagination';

export default class Blog extends React.Component {
  render() {
    const posts = this.props.data.allWordpressPost.edges

    let parentCat = {
        wordpress_id: 0
    };

    let subCat = {
        wordpress_id: 0
    };
    
    return (

        <Layout data={this.props.data} category={parentCat} subCategory={subCat}>
            <SEO title="Blog" />
            {
                posts.map(node => {
                    let parentCat = null;
                    let subCat = null;
            
                    node.node.categories.forEach(cat => {
                        if( cat.parent_element ){
                            parentCat = cat.parent_element;
                            subCat = {
                                slug: cat.slug,
                                name: cat.name,
                                wordpress_id: cat.wordpress_id
                            };
                        } else {}
                    });

                    return (
                        <ArticleCard key={node.node.id} id={node.node.id} link={"/" + parentCat.slug + "/" + subCat.slug + "/" + node.node.slug} title={node.node.title} media={node.node.featured_media} excerpt={node.node.excerpt} subheadline={node.node.acf.sub_headline} author={node.node.author} date={node.node.date} parentCategory={parentCat} subCategory={subCat} />
                    );
                })
            }
            <Pagination pageContext={this.props.pageContext} catSlug="blog" category={parentCat} subCategory={subCat} />
        </Layout>
    )
  }
}
export const blogQuery = graphql`
    query blogQuery($skip: Int!, $limit: Int!) {
        allWordpressPost(
            limit: $limit
            skip: $skip
            sort: {fields: date, order: DESC}
        ) {
            edges {
              node {
                id
                date
                title
                slug
                excerpt
                author {
                    name
                    acf {
                        avatar_image {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 100 ) {
                                        ...GatsbyImageSharpSizes
                                    }
                                    fluid(maxWidth: 100, maxHeight: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                  }
                date
                categories {
                    name
                    slug
                    id
                    wordpress_parent
                    parent_element {
                      name
                      slug
                    }
                }
                link
                featured_media {
                    localFile {
                        childImageSharp {
                            sizes(maxWidth: 2064 ) {
                              ...GatsbyImageSharpSizes
                            }
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                acf {
                    sub_headline
                }
              }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
        primaryCategories: allWordpressCategory(filter: {wordpress_parent: {eq: 0}, wordpress_id: {ne: 1}}, sort: {fields: name}) {
            edges {
                node {
                    count
                    id
                    slug
                    name
                    wordpress_parent
                    wordpress_id
                    parent_element {
                        slug
                        name
                    }
                }
            }
        }
        secondaryCategories: allWordpressCategory(filter: {wordpress_parent: {ne: 0}}, sort: {fields: name}) {
            edges {
                node {
                    count
                    id
                    slug
                    name
                    wordpress_parent
                    wordpress_id
                    parent_element {
                        slug
                        name 
                    }
                }
            }
        }
    }
`;